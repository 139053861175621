@import "../../../styles/variables";

.search {
	position: relative;
	flex: 1 0;
	// margin: 0 20%;
	// border-radius:10px;
	// padding: 0 20px;
	input {
		color: white;
		background-color: $warm-grey-heavy;
		width: 100%;
		border: none;
		box-sizing: border-box;
		position: relative;
		z-index:1;
		padding: 10px;
		&.search-box {
		  border-radius: 20px;
			padding: 10px 60px 10px 28px;
		}

		&:focus, ::selection {
			outline: none;
			background-color: $charitrek-even-darker-blue;
		}
	}
	i.search-icon {
		position: absolute;
		font-size: 1.2rem;
		top: 50%;
		transform: translateY(-50%);
		z-index: 2;

		&.search {
			pointer-events: none;
			left: 5px;
			right: auto;
		}
		&.close {
			cursor: pointer;
			border-radius: 10px;
			right: 28px;
			top: 50%;
			background-color: $warm-grey-light;
		}
		&.adv {
			cursor: pointer;
			border-radius: 10px;
			right: 5px;
			top: 50%;
			background-color: $warm-grey-light;
		}
	}
	.advanced-container {
		background-color: $charitrek-even-darker-blue;
		position: absolute;
		border-radius: 30px 30px 5px 5px;
		width: 100%;
		top: 0;
		transform-origin: center 10px;
		transform: scale(1, 0);
		min-height: 2rem;
		box-sizing: border-box;
		padding: 3rem 10px 10px 10px;
		z-index: 0;
		.location-pref {
			display: flex;
			justify-content: space-between;
			align-items: center;
			margin: 10px 0;
			> p {
				margin: 0 10px;
			}

			.btn-near {
				background-color: $chosen-purple;
				border-radius: 30px;
				padding: 3px 10px 2px 5px;
				font-size: 0.8rem;
			}
		}
		.within {
			display: inline-flex;
			flex: row nowrap;
			align-items: center;
			input {
				width: 75px;
				margin: 0 10px;
				text-align: center;
			}
		}
	}
}
