@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins/responsive.scss";

.modal-content.preview {
	&,.preview {
		display: flex;
		color: $charitrek-grey;

		&-image {
			width: 50%;
			max-width:350px;
			align-self: stretch;
			// height:400px;
			box-sizing: border-box;
			background-color: rgba(0,0,0,0.2);
			background-size: cover;
			background-position: center;
		}

		&-content {
			display: flex;
			flex-flow: column nowrap;
			flex-grow: 1;
			align-self: center;
			padding: 40px;
			box-sizing: border-box;
			&_title {
				font-size: 1.8em;
				font-weight: normal;
				align-self: flex-start;
				text-align: left;
				text-shadow: 5px 7px 2px rgba(#000, 0.1);
			}

			&_details {
				display: flex;
				flex: column nowrap;
				justify-content: space-between;
				margin: 30px 0;
				box-sizing: border-box;
				&--author {
					display: flex;
					flex-flow: row nowrap;
					align-items: center;
					text-align: left;

					h5,h3 {
						line-height: 1;
					}

					img {
						width: 40px;
						height: 40px;
						border-radius: 40px;
						border: 0px solid rgba(0,0,0,0.2);
						margin-right: 20px;
					}

					& > div {
						display: flex;
						flex-flow: column nowrap;
						align-items: flex-start;
					}
				}

				h2.price {
					font-weight: normal;
					span {
						position: relative;
						font-size:0.5em;
						vertical-align: top;
						top:0.5em;
					}
				}
			}

			.bar {
				border-top: 1px solid $charitrek-grey;
			}

			&_purchase {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				margin: 30px 0;
				span {
					display: inline-flex;
					align-items: center;
					text-transform: uppercase;
					font-size: 0.7em;
					.icon {
						font-size: 3.2em;
						color: $charitrek-even-darker-blue;
					}
				}

				@include resp_maxw(768px) {
					flex-flow: column-reverse;
				}

				button {
					background-color: $charitrek-grey;
					border: none;
					padding: 20px 60px;
					color: white;
					font-size: 1em;
					text-transform: uppercase;
					&:focus {
						outline: 2px solid $charitrek-medium-blue;
						box-shadow: 7px 7px 5px rgba($dark-grey, 0.3);
					}
				}
			}
		}

		@include resp_maxw(768px) {
		  flex-flow: column;

		  &-image {
		    min-height: 250px;
		    width: 100%;
				max-width:initial;
		  }
			&-content {
				align-self: auto;
				padding: 20px;
				&_purchase {
					align-items: center;
				}
			}
		}

		@include resp_maxw(320px) {
			&-content {
				&_title {
					width: 100%;
					text-align: center;
				}

				&_details {
					flex-flow: column-reverse;
					justify-content: space-between;
					margin: 5px 0 20px;
					height: 7em;
				}
			}
		}
	}
}
