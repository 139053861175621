@use '../styles/index';

.splash {
	.title {
		h1 {
			font-size: 2.6em;
			font-weight: 700;
		}

		@media screen and (max-width: 425px) {
			h1 {
				font-size: 2.2em;
			}
		}
	}

	p {
		line-height: 1.5em;
		max-width:510px;
		margin:0 auto 10px;
		padding:20px 0 10px;
		text-align: left;
	}

	.explain {
		h3 {
			text-transform: uppercase;
			font-size:1.3em;
			margin:0;
			padding:0;
			font-weight: 700;
		}
		h2 {
			padding-top:10px;
			line-height: 1em;
			margin:0;
		}

		// .cta {

		// }

	}

	.content-copy {
	  padding: 20px 80px;
		@media screen and (max-width: 425px) {
		  padding: 20px 20px;
		}
	}

	h2 {
	  font-size: 2.6em;
		font-weight: 700;
		@media screen and (max-width: 425px) {
			font-size: 2em;
		}
	}

	.tour {

		padding:20px 0;
		width:100%;

		h2 {
			margin:0;
			line-height: 1;
			letter-spacing: -2px;
		}

		h3 {
			margin:0;
			text-transform: uppercase;
			font-size: 1.7em;
			font-weight: 700;
			@media screen and (max-width: 425px) {
			  font-size: 1em;
			}
		}
	}

	sup {
		top: -4px;
		position: relative
	}
}
