@import "../../../styles/variables";

.browse {
	background-color: $lighter-grey;
	min-height: 100vh;
	color: black;
	padding: 0 0 20px 0;
	margin: 0 auto;

	.highlighted-slides {
		position: relative;
		// margin: 20px 0;
		// text-align: center;

		.swiper-slide {
			display: flex;
			flex-direction: column;
			align-items: center;
			justify-content: center;
			position: relative;
			max-width: 1024px;
			margin: 0 0 20px 0;
		}

		.swiper-pagination {
			// position: relative;
			width: 100%;
			margin-top:20px;
			.swiper-pagination-bullet {
				margin: 0 3px;
				background-color: $dark-grey;
				&-active {
					background-color: $charitrek-grey;
					&:focus {
						outline-color: $charitrek-grey;
					}
				}
			}
		}
	}


}
