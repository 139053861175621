@import "../../../../styles/variables";
@import "../../../../styles/mixins/responsive.scss";

.modal-content.instructions {

	.instructions {
		&-image {
			width: 50%;
			max-width:350px;
		  align-self: stretch;
			stroke: $charitrek-grey;
			background-repeat: no-repeat;
			// background-color: red;
			// -webkit-mask-image: var(--qrcode);
			// mask-image: var(--qrcode);
		}
		&-content {
			background-color: $lighter-grey;
			padding: 20px;

			&_header {
				display: flex;
				align-items: center;
				max-width: 600px;
				margin: 0 auto;
			}
			&_text {
					padding: 20px;
					text-align: left;
			}

			.open-cta {
				display: inline-block;
				text-align: center;
				width: 100%;
				margin: 5px 0 20px;
				img {
				  height: 40px;
				}
			}
		}

		@include resp_maxw(768px) {
			&-image {
			  min-height: 150px;
			  width: 100%;
			  max-width: initial;
			}

			&-content {
				padding: 10px;
			}

			&-content_header {
			  flex-flow: column;
			  align-self: auto;

				.instructions-content_title {
					font-size: 1.7em;
				}
			}
		}
	}

	.links {
		margin: 20px auto;
		img {
			height: 40px;
			margin: 0 10px;
		}
	}
}
