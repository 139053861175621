@import "../../../../styles/variables";

.highlighted-trek {
	max-width: 1024px;
	.label {
		position: absolute;
		display: flex;
		flex-flow: row wrap;

		bottom: 0;
		width: 100%;
		background-color: rgba(255,255,255,0.8);
	  opacity: 0;
	  padding: 20px 0 0 20px;
		transition: all .5s;
		box-sizing: border-box;

		.text {
		  text-align: left;
			color: $charitrek-grey;
			flex: 1 1;

			h3 {
			  text-align: left;
				display: -webkit-box;
				-webkit-line-clamp: 2;
				-webkit-box-orient: vertical;
				line-height: 1.3;
				overflow: hidden;
			  flex: 1 1;
			  font-size: 1.8em;
			  text-shadow: 5px 7px 2px rgba(#000, 0.1);

			}

			h5 {
			  margin: 20px 0;
				display: -webkit-box;
				font-size: 1em;
				line-height: 1.5;
				-webkit-line-clamp: 3;
				-webkit-box-orient: vertical;
				overflow: hidden;
			}
		}

		.cta {
			justify-self: flex-end;
			align-self: flex-end;
			flex-shrink:0;
			margin-left: 20px;
			.version {
				color: $charitrek-grey;
				font-size: 0.8em;
				margin: 10px;
			}
			.price-link {
				display: block;
				padding: 20px 30px;
				width: 100px;
				background-color: $warm-grey-heavy;
				color: white;
				text-decoration: none;
			}
		}

		@media screen and (max-width: 767px) {
			flex-flow: column nowrap;
			font-size: 0.8em;
			position: relative;
			opacity: 1;

			.text {
				h3,h5 {
					-webkit-line-clamp: 5;
					-webkit-box-orient: vertical;
				}
			}

			.version {
				display: none;
			}
			.cta {
				align-self: center;
				margin: 20px 0;
			}
		}
	}

	.slide.image {
		width: 100vw;
		max-width: 1024px;
		height: 550px;
		background-size: cover;
		background-position: center;
		@media screen and (max-width: 767px) {
			height: 300px;
		}
	}

	@media screen and (max-width: 767px) {
		box-shadow: 0px 3px 15px rgba(#000, 0.4);
	}
}

.swiper-slide-active {
	.highlighted-trek {
		box-shadow: 0px 3px 15px rgba(#000, 0.4);
		.label {
			opacity:1;
		}
	}
}
