@import "../../../../styles/variables.scss";

.paymentSetupForm {
	.cardSelection {
		display: flex;
		flex-flow: wrap;
		max-width: 700px;
		margin: 0 auto;
	}

	.payment-charge {
		margin: 40px 0 20px;

		.payment-error {
			color: $red;
		}
	}

	.payment-card {
		margin: 10px 0;
		width: 300px;
	}

	.payment-paypal {
		margin: 20px 0 0;
	}

	&.loader {
		width: 100%;
		margin: 0 auto;
		display: flex;
		flex-flow: column nowrap;
		align-items: center;
	}

	button {
		@include box-shadow;

		&:disabled {
		  opacity: 0.5;
		  cursor: default;
		}
	}
}
