.viewCard {
	position: relative;
	// min-width: 49%;
	min-height: 150px;
	flex:0.5 0 40%;
	margin: 10px 5%;
	// width: 50%;
	display: flex;
	justify-content: center;
	align-items: center;
	box-sizing: border-box;
	cursor: pointer;
	border: 3px solid transparent;

	.card-container {
		display: flex;
		flex: 1;
		justify-content: center;
		align-items: center;
		height: 100%;
	}

	&.selected {
		border: 3px solid #666;
	}

	&:not(.add) {
		box-shadow: 0px 2px 5px rgba(0,0,0,0.2);
	}

	&:last-of-type {
		flex-grow: 0;
	}

	&:only-of-type {
	  margin: 10px auto;
	}

	&.add {
		border:2px dashed rgba(0, 0, 0, 0.12);
		user-select: none;
	}

	.card-type {
		display: flex;
		justify-content: flex-end;
		align-items: center;
		flex:0.3 0 calc(30% - 10px);
		padding: 5px;
		box-sizing: border-box;
		user-select: none;

		img {
			height: 30px;
			width: 40px;
		}
	}

	.card-info {
		display: flex;
		flex:0.7 0 calc(70% - 20px);
		margin: 0 0 0 20px;
		text-align: left;
		flex-flow: column nowrap;
		box-sizing: border-box;
		user-select: none;

		.card-title {
			text-transform: capitalize;
			font-weight: 500;
		}

		p:not(.card-title) {
		  color: #666;
			font-weight: 200;
			font-size: 0.9em;
		}
	}

	.remove-card {
		padding: 10px;
		position: absolute;
		bottom: 0;
		right: 0;
		user-select: none;
	}

	.card-confirm {
		padding: 5px 20px;
		p {
			line-height: 1;
			margin-bottom: 10px;
		}

		.buttons {
			display: flex;
			flex-flow: row nowrap;

			button {
				padding: 5px 20px;
				margin: 0 5px;
				flex: 1 0 calc(50% - 10px);
			}
		}
	}

	@media (max-width: 425px) {
		flex:1 0 calc(100% - 40px);
	}
}
