@import "./variables";

h2 {
  font-size:2.6em;
}
h3 {
  font-size:2em;
}

.flex-newline {
  flex-grow:1;
  flex-basis: 100%;
}

.band--blue-green-90 { background-color: rgba($blue-green, 0.9) }
.band--light-blue-90 { background-color: rgba($light-blue, 0.9) }
.band--blue { background-color: rgba($blue,1) }
.band--blue-90 { background-color: rgba($blue, 0.9) }
.band--blue-30 { background-color: rgba($blue, 0.3) }
.band--white-80 { background-color: rgba(white, 0.8) }

.band-50 { height:50px; }
.band-40 { height:40px; }
.band-20 { height:20px; }
.band-10 { height:10px; }

.blue{background-color: $blue;}
.blue-green{background-color: $blue-green;}
.light-blue{background-color: $light-blue;}
.green{background-color: $green;}
.dark-grey{background-color: $dark-grey;}
.green-brown{background-color: $green-brown;}
.light-brown{background-color: $light-brown;}
