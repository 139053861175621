@import "../../../../styles/variables";
@import "../../../../styles/mixins/responsive.scss";

.play-modal {

	.trek-content {
		display: flex;
		flex-flow: row nowrap;

		&_image {
			width: 50%;
			max-width: 350px;
			min-height: 200px;
			align-self: stretch;
			// height:400px;
			box-sizing: border-box;
			background-color: rgba(0, 0, 0, 0.2);
			background-size: cover;
			background-position: center;
		}

		&_overview {
			display: flex;
			flex-flow: column nowrap;
			flex: 1 0;
			margin: 20px 40px;
			justify-content: center;
			color: $charitrek-grey;
			align-items: center;
		}
	}

	&--image {
	  width: 50%;
	  max-width: 350px;
		min-height: 200px;
	  align-self: stretch;
	  stroke: $charitrek-grey;
	  background-repeat: no-repeat;
		background-size: cover;
	  // background-color: red;
	  // -webkit-mask-image: var(--qrcode);
	  // mask-image: var(--qrcode);
	}
	&--content {
		background-color: $lighter-grey;
		padding: 20px;
		color: $charitrek-grey;
		// display: flex;

		&_details {
			display: flex;
			flex-flow: column nowrap;
		}

		&_header {
			display: flex;
			align-items: center;
			max-width: 600px;
			margin: 0 auto;
		}

		&_text {
			padding: 20px;
			text-align: left;
		}


	}

	.open-cta {
	  display: inline-block;
	  text-align: center;
	  width: 100%;
	  margin: 5px 0 20px;

	  img {
	    height: 70px;
	  }
	}

	.links {
		margin: 20px auto;
		img {
			height: 40px;
			margin: 0 10px;
		}
	}

	@include resp_maxw(768px) {
		.trek-content {
			flex-flow: column;
			align-self: auto;

			&_image {
				min-height: 250px;
				width: 100%;
				max-width: initial;
			}
		}
	}
}
