@import "../styles/variables.scss";

.reviewIcon {
	position: relative;

	i:first-of-type {
		position:relative;
		z-index:1;
		display: inline-block;
	}

	i:not(:first-of-type) {
		position: absolute;
		left:0;
		text-shadow: none;
		z-index:0;
		display: inline-block;
	}
	i {
		&:nth-child(4n + 2) {
			color:$charitrek-medium-blue;
		}
		&:nth-child(4n + 3) {
			color:$charitrek-dark-blue;
		}
		&:nth-child(4n + 4) {
			color:$charitrek-even-darker-blue;
		}
	}


}
