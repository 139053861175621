@font-face {
	font-family: "contained";
	src: url("./contained.eot?275b5442f6b690817156f8c9e6666aad?#iefix") format("embedded-opentype"),
		url("./contained.woff2?275b5442f6b690817156f8c9e6666aad") format("woff2"),
		url("./contained.woff?275b5442f6b690817156f8c9e6666aad") format("woff"),
		url("./contained.ttf?275b5442f6b690817156f8c9e6666aad") format("truetype"),
		url("./contained.svg?275b5442f6b690817156f8c9e6666aad#contained") format("svg");
}
@font-face {
  font-family: "icons";
  src: url("./icons.eot?62855af5d74e7c960cff1cc40990fe26?#iefix") format("embedded-opentype"),
    url("./icons.woff2?62855af5d74e7c960cff1cc40990fe26") format("woff2"),
    url("./icons.woff?62855af5d74e7c960cff1cc40990fe26") format("woff"),
    url("./icons.ttf?62855af5d74e7c960cff1cc40990fe26") format("truetype"),
    url("./icons.svg?62855af5d74e7c960cff1cc40990fe26#icons") format("svg");
}
@font-face {
  font-family: "solid";
  src: url("./solid.eot?834488b5bc390458c626e298311da46b?#iefix") format("embedded-opentype"),
    url("./solid.woff2?834488b5bc390458c626e298311da46b") format("woff2"),
    url("./solid.woff?834488b5bc390458c626e298311da46b") format("woff"),
    url("./solid.ttf?834488b5bc390458c626e298311da46b") format("truetype"),
    url("./solid.svg?834488b5bc390458c626e298311da46b#solid") format("svg");
}
@font-face {
  font-family: "stroke";
  src: url("./stroke.eot?82773ad44fba66ac00a928d11a04fe1d?#iefix") format("embedded-opentype"),
    url("./stroke.woff2?82773ad44fba66ac00a928d11a04fe1d") format("woff2"),
    url("./stroke.woff?82773ad44fba66ac00a928d11a04fe1d") format("woff"),
    url("./stroke.ttf?82773ad44fba66ac00a928d11a04fe1d") format("truetype"),
    url("./stroke.svg?82773ad44fba66ac00a928d11a04fe1d#stroke") format("svg");
}

@font-face {
  font-family: 'Nexa Bold';
  src: url('./Nexa Bold.eot'); /* IE9 Compat Modes */
  src: url('./Nexa Bold.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Nexa Bold.woff') format('woff'), /* Modern Browsers */
       url('./Nexa Bold.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./Nexa Bold.svg#7a6e0a27b48926c5216e096daa3e1570') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  700;
}

@font-face {
  font-family: 'Nexa Light';
  src: url('./Nexa Light.eot'); /* IE9 Compat Modes */
  src: url('./Nexa Light.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
       url('./Nexa Light.woff') format('woff'), /* Modern Browsers */
       url('./Nexa Light.ttf')  format('truetype'), /* Safari, Android, iOS */
       url('./Nexa Light.svg#8daeb39b16ba4fbde275db0ba71a5206') format('svg'); /* Legacy iOS */

  font-style:   normal;
  font-weight:  200;
}

i{
  line-height: 1;
}

.ct-c { font-family: "contained"; }
.ct-s { font-family: "solid"; }
.ct-r { font-family: "stroke"; }
.ct-i { font-family: "icons"; }

i[class^="icon-"]:before, i[class*=" icon-"]:before {
  display: inline-block;
  font-family: "contained" !important;
  font-style: normal;
  font-weight: normal !important;
	vertical-align: top;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.i-lg {
  font-size: 1.3333333333333333em;
  line-height: 0.75em;
  vertical-align: -15%;
}
.i-2x { font-size: 2em; }
.i-3x { font-size: 3em; }
.i-4x { font-size: 4em; }
.i-5x { font-size: 5em; }
.i-fw {
  width: 1.2857142857142858em;
  text-align: center;
}
