@import "../../../../styles/variables.scss";

.trekTile {
  margin: 5px;
	flex: 0.25 0 350px;
	max-width: 350px;
	// max-width: 350px;
	// min-width: 350px;
	box-shadow: 0px 3px 15px rgba(#000, 0.4);
	text-decoration: none;
	position: relative;
	color: $charitrek-grey;

	@media screen and (max-width: 1440px) {
		flex: 0.25 0 calc(25% - 10px);
		max-width: calc(25% - 10px);
	}
	@media screen and (max-width: 1439px) {
		flex: 0.33 0 calc(33% - 10px);
		max-width: calc(33% - 10px);
	}
	@media screen and (max-width: 1023px) {
		flex: 0.5 0 calc(50% - 10px);
		max-width: calc(50% - 10px);
	}
	@media screen and (max-width: 767px) {
		flex: 1 0 calc(100% - 10px);
		max-width: calc(100% - 10px);
	}

	&-container {
		display: flex;
		flex-flow: column nowrap;
		// height: 100%;
	}

	& > a {
		// display: flex;
		// flex-direction: column;
		// align-items: flex-start;
		// height: 100%;
		// height: 450px;
	}

	.card-data {
		width: 100%;
		align-self:flex-end;
	}

	.spacer {
		// flex-grow: 1;
	}

	.label {
		padding: 10px;
		display: flex;
		flex-grow: 1;
		width: calc(100% - 20px);
		min-height: 2.5em;
		background-color: white;
		.title {
			flex: 1 0;
			font-size: 1.2rem;
			font-weight: 400;
			justify-content: flex-start;
			text-align: left;
		}
		.version {
			align-self: flex-end;
			justify-content: flex-end;
			font-size: 0.8rem;
		}
	}
	.bg-grey {
		width: 100%;
		background-color: $light-grey;
		display: flex;
		i {
			font-size: 2rem;
		}

		.review {
			color: white;
			padding: 5px;
			display: flex;
			flex: 1 0 50px;

			i {
				margin: 0 5px;
			}
		}
		.purchase-area {
			display:flex;

			a {
				z-index:3;
			}

			.ct-s {
				color: $charitrek-grey;
			}

			i {
				color:white;
				margin: 0 2px 0 0;
				padding: 5px;
				display: flex;
				align-items:center;
				background-color: $grey;
				height: 100%;
				box-sizing: border-box;
			}
			.price {
				flex: 1 1;
				font-size: 0.9rem;
				padding: 2px 15px;
				color: white;
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: $warm-grey-heavy;
				// z-index:3;

				&:hover {
					// color: $lighter-grey;
					background-color: $warm-grey-light;
					color: $charitrek-medium-blue;
				}
			}
		}


	}

	.overall-reviews {
		padding: 5px 10px;
		display: flex;
		font-size: 0.8rem;
		align-items: center;
		justify-content: space-between;
		background-color: white;
	}

  a {
    text-decoration: none;
  }

  div.promo-image {
    height: 200px;
		min-width: 150px;
		width: 100%;
    background-position: center;
    background-size: cover;
  }

  .trek-link {
		width: 100%;
		height: 100%;
		position: absolute;
		// background-color: rgba(blue, 0.2);
  }

	&:hover {
	  // z-index:1;
		color: lighten($charitrek-grey, 10%);
	}
}
