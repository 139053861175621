@import "../../../styles/variables";

.app {
	&-header {
		position: relative;
		z-index:99;
		display: flex;
		flex-flow: column;
		background-color: $warm-grey-light;

		&-logo {
			position: absolute;
			// max-width: 50px;
			height: 80px;
			padding: 5px 20px;
			top: 5px;
			z-index:1;
		}
		a {
		  color: white;
		  text-decoration: none;
		  text-transform: uppercase;
		  display: inline-flex;
		  align-items: center;
		  font-size: 0.8rem;
		  vertical-align: baseline;

		  i {
		    font-size: 2rem;
		    margin: -2px 5px 0px;
		    align-items: center;
		    // transform: translate(-50%, -50%);
		    // top: 50%;
		  }
		}

		&-bar {
			background-color: $warm-grey-light;
			display: flex;
			justify-content: space-between;
			align-items: center;;
			position: relative;
			// max-width: 50px;
			height: 50px;
			width: 100%;
			max-width: 1024px;
			margin: 0 auto;
			color: white;
			z-index:999;



			.account,.links {
				display: flex;
				justify-content: space-evenly;
				align-items: center;
			}

			.links {
				flex: 1 0;
				margin-left: 80px;
				min-width: 330px;
			}

			.account {
				margin: 0 10px;
			}

			> .search {
				flex:1 0;
				margin: 0 60px;
				max-width: 250px;

				@media screen and (max-width:768px) {
					margin: 0 10px;
				}
			}

			&_sub {
				background-color: $lighter-grey;
				.container {
					// padding: 0 200px;
					display: flex;
					max-width: 904px; //1024 - 120 for logo
					margin: 10px auto 0;
					// width: 100%;
					flex-grow: 1;
					position: relative;
					padding-left: 120px;
					// justify-content: space-around;
					// align-items: center;
				}

				a {
					color: $charitrek-grey;
					margin: 0 20px;
					border-bottom: 2px solid transparent;
					padding: 0 20px 10px 10px;
					user-select: none;

					&.active {
						border-bottom: 2px solid $charitrek-grey;
					}
				}
			}
		}
		@media screen and (max-width: 768px) {
			.links {
					min-width: 100px;
					margin-left: 90px;
					a span {
					display: none;
				}
			}
			.account {
				a span {
					display: none;
				}
			}
		}
	}
}
