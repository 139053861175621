@import '../../styles/variables';

.legal {
	padding-bottom: 40px;
	text-align:left;
	min-height:calc(100vh - 60px - 40px);
	background-color: #fff;

	&:before {
	  background-color: $light-blue;
	  display: block;
	  content: '';
	  height: 10px;
	}

	.title {
		padding:40px 0;
		background-color: $blue;
		text-align: center;
	}

  p, ul li {
    margin: 1em auto;
    padding: 0 10px;
    max-width: 788px;
    // font-family: "Nexa Light";
    // font-size: 12px;
    color: $dark-grey;
    line-height: 1.5em;
    letter-spacing: 0.5px;
  }

  .title--sub p {
    color: $blue;
    // font-family: "Nexa Bold";
    text-align: center;
  }

  ul {
    -webkit-padding-start: 60px;
    list-style: circle;
  }

  strong {
		// font-family: "Nexa Bold";
		font-weight:900;
  }

  p.s {
    margin-top: 2em;
  }

  a {
    color: $blue;
    text-decoration: none;
	}

	@media screen and (max-width:425px) {
		.title {
			font-size: 0.8rem;
		}
	}
}
