@import "../../../styles/variables";

.app-footer {
  background-color: $warm-grey-light;
	.container {
		display: flex;
		justify-content: space-between;
		padding: 40px;
		font-size: 0.8rem;
		max-width: 1024px;
		margin: 0 auto;
		.legal-text {
			display: flex;
			flex: 1 1;
			flex-direction: column;
			align-items: flex-start;
			justify-content: flex-start;
			order:1;
		}
		.legal-links {
			width: 60%;
			display: flex;
			flex: 1 0;
			justify-content: space-between;
			order:1;
			a {
				color: white;
				text-decoration: none;
			}
		}
		.social-media {
			order:2;

			i {
				font-size: 2.5rem;
			}
		}
		.copyright {
			margin-top: 3rem;
			order:3;
		}

		@media screen and (max-width:767px) {
			flex-flow: column nowrap;
			align-items: stretch;

			.social-media {
				order:0;
				margin: 0 0 10px 0;
			}
			.legal-text {
				align-items: center;
			}
			.legal-links {
				flex-flow: column nowrap;
				a {
					margin: 5px 0;
				}
			}
			.copyright {
				margin-top: 10px;
			}
		}
	}
}
