@import "../styles/index.scss";

.fivehundred {
	height: 100vh;
	background-size: cover;
	background-position: center;
	color: white;
	text-shadow: 3px 3px 3px rgba(0,0,0,0.1);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	box-sizing: border-box;
	font-family: brandon-grotesque, sans-serif;
	text-align: center;
	overflow:hidden;
	background-color:$blue;

	// .bg {
		// position:absolute;
		// height:calc(100vh + 8px);
		// width:calc(100vw + 8px);
		// left: -4px;
		// top: -4px;
		// background-color: rgba(#666, 0.9);
		// background-color: rgba(darken($blue, 10%), 0.9);
		// background-blend-mode: luminosity;
		// background-size: cover;
	// }

	.main {
		margin: 20px 0;
		max-width: 600px;
		position:relative;
		@include text-shadow();

		h2 {
			margin: 20px;
		}

		a {
			color:#bbb;
		}
	}
}
