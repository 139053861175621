@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins/responsive.scss";

.modal-content.signin {
	&,.signin {
		color: $charitrek-grey;
		padding: 20px;

		.signin-fields {
			max-width: 500px;
			justify-content: center;
			margin: 10px auto 0;

			@include resp_maxw(425px) {
			  flex-flow: column;
			}

			.modal-input {
				flex-grow: 1;
				margin-right: 10px;

				&.password {
					a {
						text-align: right;
						text-decoration: none;
						text-transform: uppercase;
						font-size: 0.6em;
					}
				}
			}
		}
		button {
		  font-size: 0.8em;
		  margin: 10px;
		}
	}
}
