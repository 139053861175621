@use '../../../styles/index';
@import '../../../styles/variables';

.trek-detail {
	background-color: white;
	color: $warm-grey-heavy;

	.btn-container {
		max-width: 1024px;
		margin: 0 auto;
		button.btn-back {
			border: none;
			background-color: transparent;
			color: $charitrek-grey;
			margin: 10px 0 10px 140px;
			align-self: flex-start;
			cursor: pointer;
		}
	}

	.heading {
		position: relative;
		text-align: left;
		box-shadow: 0px 3px 15px rgba(#000, 0.4);
		z-index: 1;

		.images {
		  height: 550px;
			// display: flex;
			// flex-flow: row nowrap;
			.slide.image {
				height: 550px;
				// width: 1024px;
				width: 100%;
				background-position: center;
				background-size: cover;
				max-width: 1024px;


			}
			@media screen and (max-width: 768px) {
			  height: 300px;
				.slide.image {
					height: 300px;
				}
			}

			.swiper-container {
				background-color: $lighter-grey;
			}

			.swiper-pagination {
			  // position: relative;
				width: 100%;
				.swiper-pagination-bullet {
					margin: 0 3px;
					background-color: $dark-grey;
					&-active {
						background-color: $charitrek-grey;
						&:focus {
							outline-color: $charitrek-grey;
						}
					}
				}
			}

			.swiper-slide {
			  display: flex;
			  flex-direction: column;
			  align-items: center;
			  justify-content: center;
			  position: relative;
				max-width: 1024px;
			}

		  img {
				// width: 100%;
				max-height: 550px;
				// max-height: 550px;
				// width: auto;
		    // max-width: 768px;
		    // height: 100%;
				// max-width: 1024px;
		  }
		}

		.action-top {
			display: flex;
			padding: 30px 10px;
			max-width: 1024px;
			margin: 0 auto;
		}

		.title {
			font-size: 2rem;
			flex: 1 0;
			text-align: left;
		}
		&-right {
			justify-self: flex-end;
			// flex: 0.2 1;

			.author {
				width: 100%;
				display: flex;
				flex-direction: row;
				// justify-content: flex-end;
				align-items: center;
				img {
					width: 30px;
					height: 30px;
					border-radius: 15px;
				}
				i {
					background-color: $warm-grey-heavy;
					color: white;
					border-radius: 20px;
					font-size: 1.6rem;
				}
				p {
					font-size: 1.2rem;
					width: 100%;
					padding: 10px;
				}
			}

			.version {
				text-align: right;
			}
		}

		.action-bar {
			background-color: $light-grey;
			.content {
				display: flex;
				justify-content: space-between;
				flex:1;
				max-width: 1024px;
				margin: 0 auto;
				.col-left {
					display: flex;
					color: white;
					align-items: center;
				}
				.col-right {
					display: flex;
					color: white;

					.ct-s {
						color: $charitrek-grey;
					}

					i {
						background-color: $grey;
						margin: 0 5px 0 0;
						cursor: pointer;
					}
					.price {
						background-color: $charitrek-grey;
						padding: 10px 55px;
						display: flex;
						align-items: center;
						cursor: pointer;
					}
				}
				i {
					font-size: 2.4rem;
					padding: 8px 10px;
				}
				@media screen and (max-width:768px) {
				  i {
						padding: 8px 5px;
					}
				}
			}
		}
		.action-lower {
			padding: 10px 5px;
		  display: flex;
		  flex-flow: row nowrap;
		  max-width: 1024px;
		  margin: 0 auto;
			.review {
				display: flex;
				align-items: center;
				text-align: left;
				flex: 1 0;
				& > span{
					margin: 0 5px;
				}
			}
			.achievements {
				display: flex;
				align-items: center;
				i {
					font-size: 2rem;
				}
			}
		}
	}
	&-body {
		background-color: $lighter-grey;

		&-container {
			position: relative;
			margin: 0 auto;
			max-width: 1024px;
			text-align: left;
			padding: 80px 0;

			display: flex;
			flex-flow: row nowrap;
			// align-items: flex-start;

			.column {
				box-sizing: border-box;
				flex: 0.5 1 calc(50% - 50px);
				order: 0;

				&-left {
					margin: 0 40px 0 10px;
				}
				&-right {
					margin: 0 10px 0 40px;
					order: 0;
				}
			}

			@media screen and (max-width: 767px) {
				flex-flow: column nowrap;
				padding: 40px 0;
				.column {
					flex: 1 1;

					&-left,&-right {
						margin: 0 10px;
					}
				}
			}



			h4 {
				margin: 0 0 0.5em;
			}

			.section {
				margin-bottom: 2em;
			}

			p {
				line-height: 1.5;
			}


			.title {

			}

			.summary {

			}



		}
	}

	.mobile {
	  display: none;
	}
	@media screen and (max-width: 767px) {
	  // & {
	  // 	flex-flow: column wrap;
	  // }

		.mobile {
			display: block;
		}
		.desktop {
			display: none;
		}
	}
}
