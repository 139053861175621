@import "../../../../../styles/variables.scss";
@import "../../../../../styles/mixins/responsive.scss";

.addCard {
  position: relative;
  // min-width: 49%;
  min-height: 150px;
  flex: 0.5 0 40%;
  margin: 10px 5%;
  // width: 50%;
	display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  cursor: pointer;
  // border: 3px solid transparent;
	border:2px dashed rgba(0, 0, 0, 0.12);

	& form {
		display: flex;
		flex-flow: column nowrap !important;
		flex: 1;
		padding: 10px;
		justify-content: center;
	}

	button.addButton {
		padding: 10px;
		font-size: 0.7em;
		margin: 10px auto 0;
		width: 50%;
		user-select: none;
		&:hover {
		  filter: contrast(115%);
		}

		&:disabled {
		  opacity: 0.5;
		  cursor: default;
		}
	}

  .card-container {
    display: flex;
    flex: 1;
  }

	.remove-card {
	  padding: 10px;
	  position: absolute;
	  bottom: 0;
	  right: 0;
	}

	.card-error {
		color: $red;
		font-size: 1em;
		line-height: 1;
		padding-top: 10px;
	}

	@media (max-width: 425px) {
	  flex: 1 0 calc(100% - 40px);
	}
}
