@import "../../../../styles/variables.scss";
@import "../../../../styles/mixins/responsive.scss";

.modal-content.payment {
	&,.payment {
		display: flex;
		color: $charitrek-grey;
		flex-flow: column nowrap;

		&-image {
			width: 50%;
			max-width:350px;
			align-self: stretch;
			// height:400px;
			box-sizing: border-box;
			background-color: rgba(0,0,0,0.2);
			background-size: cover;
			background-position: center;
		}

		&-content {
			display: flex;
			flex-flow: row nowrap;
			// flex: 1 0;
		}

		&-overview {
			display: flex;
			flex-flow: column nowrap;
			flex: 1 0;
			margin: 20px 40px;
			justify-content: center;

			&_details {
				display: flex;
				flex-flow: column nowrap;
				flex: 1 0 100%;
				justify-content: space-between;


			}

			.detail_row {
				display: flex;
				flex-flow: row nowrap;
				justify-content: space-between;
				// flex: 1 0 100%;
				width: 100%;
				margin: 2px 0;
				text-align: left;
				align-items: flex-end;
			}
		}

		.bar {
		  border-top: 1px solid $charitrek-grey;
			margin: 20px 0;
		}

		&-area {
			margin: 20px 0;
		}

		&-method {
			padding: 40px 20px;
			background-color: $lighter-grey;

			&_select {
				display: flex;
				align-items: center;
				justify-content: center;

				.radio {
					display: flex;
					align-items: center;
					cursor: pointer;
					position: relative;
					&:before {
						content: '';
						height: 15px;
						width: 15px;
						border-radius: 15px;
						background-color: white;
						display: inline-block;
						margin-right: 10px;
						// margin-left: -15px;
					}
					&:after {
						content: '';
						left: 4px;
						height: 7px;
						width: 7px;
						border-radius: 7px;
						position: absolute;
						transition: transform 0.25s 0.05s;
						transform: scale(0);
					}

					&.select {
						&:before {
							background-color: $charitrek-grey;
						}
						&:after {
							background-color: $charitrek-medium-blue;
							transform: scale(1);
						}
					}
				}

				&:before, &:after {
					content: '';
					height: 0;
					width: 20%;
					margin: 0 20px;
					border-top: 2px solid $charitrek-grey;
				}
			}

			/* .credit {

				form {
					margin: 20px auto;
					display: flex;
					flex-flow: row wrap;
					// align-items: flex-start;
					justify-content: flex-start;
					max-width: 320px;

					label {
						margin-top: 10px;
						// max-width: 200px;
						// flex:1 0 100%;
						font-size: 0.7em;
						text-align: left;
						font-family: $primary-font;
						color: $charitrek-grey;
					}

					input, select {
						// max-width: 100px;
						width: auto;
						border: none;
						padding: 10px 20px;
						font-size: 0.6em;
						flex: 1 0 100%;
						// flex-shrink: 1;
						font-family: $primary-font;
						color: $charitrek-grey;
						box-sizing: border-box;
						&::placeholder {
							text-transform: uppercase;
							color: $lighter-grey;
						}

						.shrink {
							flex: 0 1;
						}
					}

					input.postal {
						flex:1 0;
					}
				}
				.StripeElement {
					padding:10px;
				}
			} */
		}

		@include resp_maxw(768px) {

		  &-image {
		    min-height: 250px;
		    width: 100%;
				max-width:initial;
		  }
			&-content {
			  flex-flow: column;
				align-self: auto;
			}
			&-overview {
			  margin: 20px;
			}

			&-method {
				&_select {
					&:before, &:after {
						margin: 0 10px;
					}
				}
				.radio {
				  h4 {
				    font-size: 0.9em;
				  }
				}
			}
		}
	}
}
