@mixin resp_maxw( $width ) {
    @media all and ( max-width: $width ) {
        @content;
    }
}
@mixin resp_maxh( $height ) {
    @media all and ( max-height: $height ) {
        @content;
    }
}

@mixin resp_minw( $width ) {
    @media all and ( min-width: $width ) {
        @content;
    }
}
@mixin resp_minh( $height ) {
    @media all and ( min-height: $height ) {
        @content;
    }
}
@mixin resp_betweenW( $minwidth, $maxwidth ) {
    @media all and ( min-width: $minwidth ) and ( max-width:$maxwidth ) {
        @content;
    }
}
