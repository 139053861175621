.account-type--links {
	margin:0 auto;
	button {
		padding:0;
		font-size: 1.25rem;
		display:flex;
		width: auto;
		margin-top: 10px;
		margin-right: 20px;
		// justify-content: center;
		align-items: center;
		background-color: transparent !important;
		text-transform: initial;
		cursor: pointer;
	}
	.icon {
		font-size:2em;
	}
}
