@import "../../../styles/variables.scss";
@import "../../../styles/mixins/responsive.scss";

.purchaseModal {
	width: 100%;
	position: absolute;
	z-index: 9999;
	top: 0;
	display: flex;
	padding-top: 100px;
	justify-content: center;

	.bg {
		height: 100%;
		width: 100%;
		position: fixed;
		top: 0;
		z-index:-1;
		background-color: rgba(0,0,0,0.2);
	}

	.modal {
		width: 80%;
		max-width: 1024px;
		background-color: white;
		position: fixed;
		max-height: calc(100vh - 100px);
		display: flex;
		flex-flow: column nowrap;
		box-shadow: 0px 3px 15px rgba(#000, 0.4);

		.top {
			background-color: $charitrek-medium-blue;
			display: flex;
			align-items: center;
			justify-content: flex-start;
			padding: 10px;
			color: white;

			> button {
				border: none;
				background: none;
				color: white;
				font-size: 0.7rem;
				text-transform: uppercase;
				display: flex;
				align-items: center;
				cursor: pointer;
				&:focus {
					color: $charitrek-grey;
					outline: none;
				}

				> span {
					font-size: 1rem;
					margin-right: 5px;
				}
			}
		}

		&-input {
		  display: flex;
		  flex-flow: column nowrap;
			text-align: left;
		  label {

		  }

			input {
				background-color: $lighter-grey;
				border: none;
				padding: 10px;
				font-family: inherit;
				text-transform: uppercase;
				font-size: 0.6em;
			}
		}

		.row {
			display: flex;
			flex-flow: row;
		}

		.break {
		  display: inline-flex;
		  align-items: center;
		  justify-content: center;

		  &:before, &:after {
		    content: '';
		    height: 0;
		    width: calc(20% - 10px);
		    margin: 0 10px;
		    border-top: 2px solid $charitrek-grey;
		    display: inline-block;
		  }
		}

		&-content {
			overflow: scroll;
			button {
				background-color: $charitrek-grey;
				border: none;
				padding: 20px 60px;
				color: white;
				font-size: 1em;
				text-transform: uppercase;
				cursor: pointer;
				&:focus {
					outline: 2px solid $charitrek-medium-blue;
					box-shadow: 7px 7px 4px rgba($dark-grey, 0.1);
				}
			}
		}

		@include resp_maxw(768px) {
		  width: 90%;
		}
	}
}
