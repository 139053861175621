@import "../../../../styles/variables.scss";

.review-group {
	.review-overall {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-evenly;
		align-items: center;
		margin-bottom: 2rem;

		.review-filter {
			flex: 1 0 100%;
			width: 100%;
			display: flex;
			justify-content: space-evenly;
			margin: 10px 0 30px;

			> button {
				border :none;
				background-color: transparent;
				outline: none;
				font-family: $brandon;
				text-transform: uppercase;
				&.active {
					text-decoration: underline;
					color: $chosen-purple;
				}
			}
		}

		.review-categories {
			display: flex;
			flex-flow: column;
			i {
				font-size: 1.75rem;
			}
		}

		.review-overall-summary {
			display: flex;
			flex-flow: column nowrap;
			justify-content: center;
			align-items: center;

			h4 {
				font-size: 1.7em;
				margin:0;
			}

			p {
				display: inline-flex;
				align-items: center;
			}
		}

		.reviews {
			width: 200px;
			.review-section {
				display: flex;
				align-items: center;
				justify-content: space-between;
				margin: 0px 0;
				.starReviewIcon span {
					font-size: 0.9rem;
					line-height: 0.6;
				}
				> p {
					width: 15px;
					font-size: 0.8rem;
					line-height: 0.95;
				}
			}
			.overall {
				flex: 1;
				height: 5px;
				background-color: darken($lighter-grey, 2%);
			}
			.overall-highlight {
				height: 5px;
			}
			.overall-5 { background-color: $charitrek-even-darker-blue; }
			.overall-4 { background-color: $charitrek-dark-blue; }
			.overall-3 { background-color: $charitrek-medium-blue; }
			.overall-2 { background-color: $charitrek-light-green; }
			.overall-1 { background-color: $charitrek-dark-green; }
		}
	}

	@media screen and (max-width:767px) {
		background-color: white;
		margin: 20px -10px 40px;
		padding: 10px 10px;
		box-shadow: 0px 3px 15px rgba(#000, 0.4);
	}
}
