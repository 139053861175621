@import '../../styles/variables';

.header {
  &.fixed {
    background-color: $dark-grey;

    .logo {
      opacity: 1;
      visibility: visible;
    }
	}

  color:white;
  transition: background 0.25s, box-shadow 0.25s;
  padding:15px 25px;
  position: fixed;
  z-index: 99;
  width: 100vw;
  box-sizing: border-box;

  a {
    color:white;
  }

  .logo {
    transition: all 0.5s;
    opacity:0;
		visibility: hidden;
		width: 120px;
  }

  .nav {
    display:flex;
    flex-flow: row;
    align-items: center;
  }

  ul {
    margin:0;
    padding:0;
  }

  a {
		text-transform: uppercase;
		text-decoration: none;
	}

  .space-between {
    flex-grow: 1;
	}

	.menu-wrapper {
		display:flex;
		flex-direction: row;
		align-items: center;

		a {
			margin-left: 10px;
		}
	}

  .hamberger
  {
    margin:0 0 0 20px;
    height:18px;
    width:24px;
    background-color:$light-brown;
    border-bottom:6px solid $green-brown;
    border-top:6px solid $light-blue;
    box-sizing: border-box;
	}

	@media screen and (max-width: 320px) {
		padding:15px 10px;
	}
}
