$brandon: brandon-grotesque, sans-serif;
$primary-font: $brandon;

$blue: #60c6dd; //Default blue
$blue-green: #8bd2e8; //Green-blue
$light-blue: #c7e9f3; //Light-blue almost gray
$green: #2dcb73;
// $dark-grey:#666666;
$green-brown:#a4bd48;
$light-brown:#c8d853;
$red:#F85A3E;
$chosen-purple: #6D4191;
$action-pink: #A756AB;


$warm-grey-heavy: #695B6B;
$warm-grey-light: #7A6E7C;
$charitrek-even-darker-blue: #3FBAD6;
$grey: #B3B3B3;
$light-grey: #BBBBBB;
$lighter-grey: #eeeeee;
$charitrek-medium-blue: #8BD2E8;
$charitrek-dark-green: #A4BD48;
$charitrek-light-green: #C8D853;
$charitrek-grey: #666666;
$charitrek-light-blue: #C7E9F3;
$charitrek-dark-blue: #60C6DD;
$default-text: #ffffff;
$dark-grey: #3c3843;

@mixin text-shadow($horz:3px, $vert:3px, $blur:2px, $color: rgba(0,0,0,0.1)) {
	text-shadow: $horz $vert $blur $color;
}
@mixin box-shadow($horz:3px, $vert:3px, $blur:2px, $color: rgba(0,0,0,0.1)) {
	box-shadow: $horz $vert $blur $color;
}
