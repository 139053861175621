@import "../../styles/variables.scss";

.page {
	min-height: 100vh;
	display:flex;
	flex-direction: row;
	&>div {
		position:relative;
	}

	@media screen and (max-height: 425px) and (orientation: landscape) {
		flex-direction: column;
		min-height:100vh;
	}
	@media screen and (max-width: 767px) {
		flex-direction: column;
		// height:auto;
		min-height:100vh;
	}

	.left {
		flex-grow:1;
		min-height: 480px;

		.bg {
			position:absolute;
			width: calc(100% + 4px);
			height: calc(100% + 4px);
			left: -2px;
			top: -2px;
			background-size: cover;
			background-position: center;
			background-blend-mode: multiply;
			background-color: rgba($dark-grey, 0.6);

			transition: opacity 0.5s;

			// &.fade-enter {
			// 	opacity:0;
			// }
			// &.fade-enter { opacity: 1; }
			&.fade-enter-done { opacity: 1; }
			&.fade-exit { opacity: 0; }
			&.slide-2 { background-color: rgba($dark-grey, 0.3); }
			// &.slide-1,
			// &.slide-3,
			// &.slide-4,
			// &.slide-5
			// { background-color: rgba($dark-grey, 0.6); }
			// &.slide-1 { opacity:0.4; }
		}

		.left-content {
			position: absolute;
			width: 100%;
			height: 100%;
			top: 0;

			.photo-area {
				position: absolute;
				width: 100%;
				height: 100%;
				display:flex;
				justify-content: center;
				align-items: center;
				transition: opacity 0.25s;

				&.pa-fade-enter { opacity: 0; }
				&.pa-fade-enter-done { opacity: 1; }
				&.pa-fade-exit { opacity: 0; }

				&--wrapper {
					max-width: 65%;

					@media screen and (max-width:768px) {
						max-width: 75%;
					}
				}
			}

			h1 {
				font-size: 2.5em;
				@include text-shadow();
				font-weight: 200;
				margin: 20px 0;
			}

			p {
				font-size:1.5em;
				font-weight: 200;
				@include text-shadow();
			}

			@media screen and (max-width: 425px) {
				.photo-area {
					&--wrapper {
						max-width: none;
						padding: 20px;
					}
				}

				h1 {
					font-size: 2em;
				}

				p {
					font-size: 1.2em;
				}
			}
		}

		.left-trans {
			position:relative;
			height:100%;
			width: 100%;
			overflow:hidden;

			@media screen and (max-height: 425px) and (orientation: landscape) {
			  position:absolute;
			}
			@media screen and (max-width: 767px) {
				position:absolute;
			}
		}

		@media screen and (max-width:425px) {
			min-height: 50vh;
		}
	}
	.right {
		flex:0.25;
		height:100vh;
		min-height: 480px;
		min-width: 280px;
		display:flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		padding:0 40px;
		position:relative;

		.topo {
			background-color: $blue;
			overflow: hidden;
			width: 100%;
			height:100%;
			position:absolute;
			background-size: cover;
		}

		h4 {
			color:white;
			@include text-shadow();
		}

		a {
			text-decoration: none;
			color: $dark-grey;
			transition: color 0.25s;
			&:visited {
				color: darken($dark-grey, 10%);
			}
			&:hover {
				color: $chosen-purple;
			}
		}

		.right-trans {
			position:absolute;
			width: 100%;
			height: 100%;
			top:0;
			display: flex;
			justify-content: center;

			.form-area {
				position:absolute;
				width: 100%;
				height: 100%;
				top:0;
				display:flex;
				flex-direction: column;
				justify-content: center;
				align-content: center;
				box-sizing: border-box;
				padding: 40px;
				max-width: 425px;
				margin: 0 auto;

				transition: opacity 0.25s;

				p {
					@include text-shadow();
				}

				&.fade-enter { opacity: 0; }
				&.fade-enter-done { opacity: 1; }
				&.fade-exit { opacity: 0; }
			}

			.social-icons {
				display: flex;
				flex-direction: row;
			  justify-content: space-evenly;
			}
		}

		@media screen and (max-width: 767px) {
		  min-height: auto;
			min-width: auto;
		  height: auto;
		  width: 100vw;
		  flex: none;
		  padding: 10px 0;
			box-sizing: border-box;

			.topo {
				// position:relative;
			}

			.right-trans {
				position:relative;
				padding: 10px 0 30px;

				.form-area {
					padding: 10px;
					position:relative;
					justify-content: flex-start;
					max-width: 425px;
					margin: 0 auto;
				}

				.social-icons {
				  justify-content: space-evenly;
				}
			}

			h1 {
				font-size: 1.3em;
			}
		}
	}

	h1 {
		font-size:2em;
		margin-bottom: 20px;
		font-weight: 400;
		line-height:1.2em;
		@include text-shadow();
	}

	.flexH {
		display: flex;
		flex-flow: row nowrap;
		margin: 0 -5px;

		.half {
			width: auto;
			flex: 1 calc(50% - 30px);
			margin: 0 5px;
			// width: calc(50% - 15px);
			// margin-left:15px;
		}
	}

	input {
		box-sizing: border-box;
		padding: 10px 15px;
		border: none;
		outline: none;
		width: 100%;
		font-family: brandon-grotesque, sans-serif;
		font-size: 1rem;
		margin-top: 10px;
	}

	em {
		color: $action-pink;
		font-size: 0.8em;
		margin: 5px 0;
	}

	.icon {
		font-size: 4em;
		color: white;
		@include text-shadow();
	}

	button {
		margin-top: 10px;
		padding: 10px 10px;
		width: 100%;
		font-size: 1rem;
		background-color: $chosen-purple;
		transition: background-color 0.25s;
		color:white;
		text-transform: uppercase;
		font-family: brandon-grotesque, sans-serif;
		border:none;
		outline:none;

		@include text-shadow();

		&:disabled {
			background-color: rgba($chosen-purple, 0.6);
			color: rgba(white, 0.4);
		}

		&:active,&:focus {
			background-color: darken($chosen-purple, 20%);
		}
	}

}
