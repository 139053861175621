@import "../constants";

.spinner {
  // margin: 100px auto;
  width: 1.5em;
  height: 1.5em;
  text-align: center;
  // font-size: 1;
  border-radius: 50%;
  overflow: hidden;
  display: inline-flex;
	vertical-align: top;
  // align-items: center;
  flex-flow: column nowrap;
	box-sizing: border-box;

	& > div {
		// background-color: #333;
		width: 100%;
		// height: 4px;
		flex: 1 0 20%;
		margin: 0 0 1%;
		// display: inline-block;
		border-radius: 50%;
		box-sizing: border-box;
		animation: sk-stretchdelay 1.2s infinite ease-in-out;
	}

	& .rect1 {
		background-color: $charitrek-even-darker-blue;
	}

	& .rect2 {
		animation-delay: -1.1s;
		background-color: $charitrek-dark-blue;
	}

	& .rect3 {
		animation-delay: -1.0s;
		background-color: $charitrek-medium-blue;
	}

	& .rect4 {
		animation-delay: -0.9s;
		background-color: $charitrek-light-green;
	}

	& .rect5 {
		animation-delay: -0.8s;
		background-color: $charitrek-dark-green;
	}
}

@keyframes sk-stretchdelay {
  0%, 40%, 100% {
    transform: scaleX(0.2);
		border-radius: 50%;
  }

  20% {
    transform: scaleX(1.0);
    border-radius: 0;
  }
}
