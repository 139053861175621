@import "../../../styles/variables";

.owned-page {
	background-color: $lighter-grey;
	min-height: 100vh;
	color: $charitrek-grey;
	padding: 20px 0;
	margin: 0 auto;

	display: flex;
	flex-direction: column;

	h3.error {
		margin: 0 15px;
		align-self: flex-start;
		color: $charitrek-grey;
	}
}
