@import "../../../../styles/variables.scss";

.review-entry {
	margin-bottom: 2.5rem;

	&_top {
		display: flex;
		flex-flow: row nowrap;

		> .title {
			flex:1 0;

			h4 {
				font-size: 1.3rem;
			}
			h5 {
				font-size: 0.9rem;
				color: $light-grey;
			}

			.date {
				position: relative;
			}
		}

		.ratings {
			display: flex;
			flex-flow: column nowrap;
			align-items: stretch;
			&-categories {
				display: flex;
				justify-content: space-evenly;

				i {
					font-size: 1.5rem;
					&.main {
						color: $grey;
					}
				}

			}
			.starReviewIcon span {
			  font-size: 0.9rem;
			}
			p {
				align-self: flex-end;
				font-size: 0.8rem;
			}
		}
	}
	.comment {
		font-size: 0.9rem;
	}

	/* Tooltip text */
	.tooltip
	{
		&text {
			visibility: hidden;
			width: 120px;
			background-color: #555;
			color: #fff;
			text-align: center;
			padding: 5px 0;
			border-radius: 6px;

			/* Position the tooltip text */
			position: absolute;
			z-index: 1;
			bottom: 125%;
			left: 50%;
			margin-left: -60px;

			/* Fade in tooltip */
			opacity: 0;
			transition: opacity 0.3s;
		}

		/* Tooltip arrow */
		& &text::after {
			content: "";
			position: absolute;
			top: 100%;
			left: 50%;
			margin-left: -5px;
			border-width: 5px;
			border-style: solid;
			border-color: #555 transparent transparent transparent;
		}
	}
	/* Show the tooltip text when you mouse over the tooltip container */
	.date:hover &text {
		visibility: visible;
		opacity: 1;
	}
}
