@import 'mixins/responsive';
@import 'constants';
@import 'reset';
@import 'variables';
@import 'components/spinner';
// @import 'fonts/icon';

html,body {
	background-color:$dark-grey;
}

body {
	font-family: $primary-font;
	-webkit-font-smoothing: antialiased;
	-moz-font-smoothing: antialiased;
	text-rendering: optimizeLegibility;
	line-height: normal;
}

// .Toastify__toast {}
// .Toastify__toast--rtl {}
// .Toastify__toast--dark {}
// .Toastify__toast--default {}
// .Toastify__toast--info {}
// .Toastify__toast--success {}
// .Toastify__toast--warning {}
.Toastify__toast--error { background-color:$action-pink; }
// .Toastify__toast-body {

sup {
	font-size: 0.5em;
	vertical-align: top;
}

h1 {
  font-size: 2.6em;
  font-weight: 700;
}
h2 {
  font-size: 2.0em;
  font-weight: 700;
}
h3 {
  font-size: 1.8em;
}
h4 {
  font-size: 1.4em;
}
h5 {
  font-size: 1.2em;
}

h1,h2,h3 {
	text-shadow: 5px 7px 2px rgba(#000, 0.1);
	font-weight: 400;
}

a {
	color: #666;
	@include text-shadow();
	&:hover {
		color:$blue;
	}
}


