@import "../styles/variables.scss";

span.starReviewIcon {
	position: relative;
	& > span {
		line-height: 1em;
		padding: 0;
		margin:0;
		letter-spacing: 1;
		font-size: 24px;
		text-shadow: none;
	}
	& > .highlighted {
		position: absolute;
		color: $charitrek-even-darker-blue;
		// color: blue;
		// top: 0em;
		// left: 0;
		z-index: 1;
	}
	& > .default {
		position: relative;
		color: $light-grey;
		// top: 0px;
		z-index: 0;
	}
}
