@import "../../../../styles/variables.scss";

.trek-list {
  display: flex;
  flex-direction: column;
  padding: 20px 0;

	> a {
		text-align: left;
		text-decoration: none;
	}

  h3 {
    // padding: 15px 10px;
		margin: 0 15px;
    align-self: flex-start;
		color: $charitrek-grey;

    i {
      margin: 0 10px;
			font-size: 1.25rem;
    }
  }


  .trek-grid {
    margin: 0;
		padding: 10px 10px;
    padding-bottom: 20px;
    display: flex;
    // flex-wrap: wrap;
    flex-flow: row nowrap;
    justify-content: flex-start;
    // margin: 0 auto;
    // max-width: 1024px + 60px;
    overflow: scroll;

		&.full {
			flex-wrap: wrap;
			overflow: auto;
		}
  }
}
