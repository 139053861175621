@import "../../../../styles/variables.scss";

.paypal {
	&-buttons {
		margin: 20px;
		width: 300px !important;
		@include box-shadow;
	}

	&-error {
		color: $red;
	}
}
