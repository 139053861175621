@import '../../styles/variables';

.footer {
	// transform:translate(0, -100%);
	color:white;
	text-align: center;
	// width:100%;
	// position:absolute;
	background-color:$green-brown;
	// font-family: "Nexa Bold";
	font-size:12px;

	&::before {
		background-color: $light-brown;
		content: "";
		display: block;
		width: 100%;
		height: 10px;
		// margin-bottom:-40px;
		position:relative;
		z-index:1;
	}

	ul {
		padding:20px;
		display:flex;
		max-width:520px;
		justify-content: space-around;
		align-items: center;
		margin: 0 auto;
	}

	li {
		list-style: none;
		font-weight: 700;
		@include text-shadow();
	}

	a {
		color:white;
		text-decoration: none;
		&:hover {
			color:$light-brown;
		}
	}
}


@media all and (max-width: 425px) {
	.footer ul{
		// flex-direction: column;
		flex-wrap:wrap;
		li {
			margin: 3px 0;
		}

		li:first-of-type {
			flex-grow: 1;
			width: 100%;
		}
	}
}
