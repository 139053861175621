@import "../styles/variables.scss";

.no-match .content {
	background-size:cover;
	height:100vh;
	width:100%;
	display:flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	padding: 20px;
	box-sizing: border-box;

	.wrapper {
		margin-bottom: 50vh;

		h1 {
			font-size:3em;
			font-weight: 500;
			@include text-shadow();
		}
		h5 {
			margin:10px;
			font-size:1.5em;
			@include text-shadow();
		}

		button {
			border:none;
			outline:none;
			background: none;
			text-decoration: none;
			color: #666;
			@include text-shadow();
			cursor: pointer;
			&:hover {
				color: $blue;
			}
		}
	}
}
